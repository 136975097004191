/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzAwZDhkMiIsIj9lcjwjYjNmM2YyIiwiO2VyPCMwMGM4YzF$LCIlPmBePCMwMDJmNTkiLCI~ZXI8I2IzYzFjZCIsIjtlcjwjMDAxZDNlfiwid2Fybj5gXjwjZmY4MzAwIiwiP2VyPCNmZmRhYjMiLCI7ZXI8I2ZmNjYwMH4sIj9UZXh0PCMwMTA2MDYiLCI~PTwjZTBmZmZmIiwiO1RleHQ8I2UwZmZmZiIsIjs9PCMwMTA2MDZ$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PFBvcHBpbnN$LGBAPCgtMyIsImZhbWlseTxQb3BwaW5zfixgQDwoLTIiLCJmYW1pbHk8UG9wcGluc34sYEA8KC0xIiwiZmFtaWx5PFBvcHBpbnN$LGBAPGhlYWRsaW5lIiwiZmFtaWx5PFBvcHBpbnN$LGBAPHRpdGxlIiwiZmFtaWx5PFBvcHBpbnN$LGBAPHN1YiktMiIsImZhbWlseTxQb3BwaW5zfixgQDxzdWIpLTEiLCJmYW1pbHk8UG9wcGluc34sYEA8Ym9keS0yIiwiZmFtaWx5PFBvcHBpbnN$LGBAPGJvZHktMSIsImZhbWlseTxQb3BwaW5zfixgQDxidXR0b24iLCJmYW1pbHk8UG9wcGluc34sYEA8Y2FwdGlvbiIsImZhbWlseTxQb3BwaW5zfixgQDxpbnB1dCIsImZhbWlseTxQb3BwaW5zIiwic2l6ZT5udWxsfV0sImljb25zPE91dGxpbmVkIiwiP25lc3M$dHJ1ZSwidmVyc2lvbj4xM30=
*/

@use "@angular/material" as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

$fontConfig: (
  display-4: mat.define-typography-level(8rem, 8rem, 300, "Poppins", -0.0134em),
  display-3: mat.define-typography-level(4rem, 4rem, 400, "Poppins", -0.0089em),
  display-2: mat.define-typography-level(3.21rem, 3.43rem, 400, "Poppins", 0em),
  display-1:
    mat.define-typography-level(2.43rem, 2.86rem, 400, "Poppins", 0.0074em),
  headline: mat.define-typography-level(1.71rem, 2.29rem, 400, "Poppins", 0em),
  title: mat.define-typography-level(1.43rem, 2.29rem, 500, "Poppins", 0.0075em),
  subheading-2:
    mat.define-typography-level(1.14rem, 2rem, 400, "Poppins", 0.0094em),
  subheading-1:
    mat.define-typography-level(1.07rem, 1.71rem, 500, "Poppins", 0.0067em),
  body-2: mat.define-typography-level(1rem, 1.71rem, 500, "Poppins", 0.0179em),
  body-1: mat.define-typography-level(1rem, 1.43rem, 400, "Poppins", 0.0179em),
  button: mat.define-typography-level(1rem, 1rem, 500, "Poppins", 0.0893em),
  caption:
    mat.define-typography-level(0.86rem, 1.43rem, 400, "Poppins", 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, "Poppins", 1.5px),
);

// Light Theme Text
$dark-text: #010606;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #e0ffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #e0ffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#010606, 20%);
$dark-bg-alpha-4: rgba(#010606, 0.04);
$dark-bg-alpha-12: rgba(#010606, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #010606;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#e0ffff, 0.04);
$light-bg-alpha-12: rgba(#e0ffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

html {
  // all fonts will be dynamically size with a min of 16px
  font-size: max(16px, 1vw);
}
// Theme Config
body {
  --primary-color: #00d6cf;
  --primary-lighter-color: #b4f3f2;
  --primary-darker-color: #00c7c0;
  --primary-slider-color: #00d6cf;
  --text-primary-color: #{$dark-primary-text};
  --background-color: #e0ffff;
  --background-alt-color: #ffffff;
  --accent-color: #002e57;
  --accent-lighter-color: #b2c0cd;
  --accent-darker-color: #001d3c;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --secondary-color: #fac800;

  --warn-color: #ff8300;
  --warn-lighter-color: #ffdab3;
  --warn-darker-color: #ff6600;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$dark-primary-text};
  --button-color: #{$light-bg-lighter-5};

  &.dark-mode {
    --primary-color: #002e57;
    --primary-lighter-color: #b2c0cd;
    --primary-darker-color: #001d3d;
    --primary-slider-color: #002e57;
    --text-primary-color: #e0ffff;
    --background-color: #{$dark-text};
    --background-alt-color: #000000;
    --accent-color: #00d6cf;
    --accent-lighter-color: #b4f3f2;
    --accent-darker-color: #00c7c0;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$light-primary-text};
    --button-color: #{$dark-bg-lighter-5};
  }
}

$mat-primary: (
  main: #00d6cf,
  lighter: #b3f3f1,
  darker: #00c6bd,
  200: #00d6cf,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: #002e57,
  lighter: #b3c0cd,
  darker: #001d3c,
  200: #002e57,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #ff8300,
  lighter: #ffdab3,
  darker: #ff6600,
  200: #ff8300,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);
$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);

.dark-mode {
  @include mat.all-component-themes($altTheme);
}
