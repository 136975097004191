.message {
  white-space: pre-wrap;

  &--success {
    background: var(--accent-color) !important;
    color: var(--text-accent-color) !important;
  }

  &--info {
    background: #f4efe4;
    color: #e99b00;
  }

  &--error {
    background: var(--warn-color) !important;
    color: var(--text-warn-color) !important;
  }
}
