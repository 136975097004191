// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em !important;
  margin: 0 0.65em !important;
  min-width: 3em !important;
  line-height: 2.57em !important;
}

.mat-standard-chip {
  padding: 0.5em 0.85em !important;
  min-height: 2.5em !important;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

img,
video {
  width: 100%;
  height: auto;
}

// navbar specific style
.nav-dropdown {
  max-width: none !important;
  .mat-menu-content {
    display: flex;
    flex-direction: column;
  }
}

.termsfeed-com---palette-light {
  &.termsfeed-com---nb {
    background-color: var(--background-color) !important;
    color: var(--text-primary-color) !important;
  }
  .cc-nb-main-container {
    border: 2px solid;
  }
  .cc-nb-title,
  .cc-nb-text,
  .cc-pc-head-title-text,
  .cc-pc-head-title-headline,
  .cc-pc-head-close {
    color: var(--text-primary-color) !important;
  }
  .cc-nb-okagree,
  .cc-cp-foot-save {
    background-color: var(--accent-color) !important;
    color: var(--text-accent-color) !important;
  }
  .cc-nb-reject {
    background-color: var(--warn-color) !important;
    color: var(--text-warn-color) !important;
  }
  .cc-nb-changep {
    background-color: var(--button-color) !important;
    color: var(--text-primary-color) !important;
  }

  .cc-pc-container,
  .cc-pc-head,
  .cc-cp-body,
  .cc-cp-foot,
  .cc-cp-foot-byline {
    background-color: var(--background-color) !important;
    color: var(--text-primary-color) !important;
  }
  .cc-cp-body-content {
    &,
    &-entry,
    &-entry-title,
    &-entry-text,
    a {
      background: var(--background-color) !important;
      color: var(--text-primary-color) !important;
    }
  }

  .cc-cp-body-tabs {
    background: var(--background-color) !important;
    color: var(--text-primary-color) !important;
    &-item {
      background: var(--background-color) !important;
      color: var(--text-primary-color) !important;

      &[active="true"] {
        background: var(--accent-color) !important;
        color: var(--text-accent-color) !important;
      }
    }
  }
  .termsfeed-com---pc-dialog {
    input[type="checkbox"].cc-custom-checkbox:checked + label:before {
      background: var(--primary-slider-color);
    }
    input[type="checkbox"].cc-custom-checkbox + label:before {
      background: var(--primary-lighter-color);
    }
  }
}
